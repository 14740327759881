import Nav from "./components/Nav"
import Hero from "./components/Hero"
import Header from "./components/Header"
import Skills from "./components/Skills"
import Projects from './components/Projects'
import ContactMe from './components/ContactMe'
import Footer from './components/Footer'

import { Parallax, ParallaxProvider } from "react-scroll-parallax"

import './styles/App.css'

function App() {

  return (
    <ParallaxProvider>
      <Nav />
      <Hero />
      <Header />
      <Skills />
      <Projects />
      <ContactMe />
      <Footer />
    </ParallaxProvider>
  )
}

export default App
