import { useEffect } from "react"
import ScrollReveal from "scrollreveal"

function Projects() {

    useEffect(() => {
        ScrollReveal().reveal(".project", {
            interval: 200,
            duration: 2100,
        })

        ScrollReveal().reveal(".title", {
            duration: 2100,
        })
    })

    return(
        <section id="projects" className="block">
            <h2 className="title">Projets</h2>
            <div className="project">
                <div className="project-image thomacon" onClick={() => window.open("https://www.ei-thomacon.com", "_blank")}></div>
                <h3>EI Thomacon</h3>
                <p>
                    Création d'un site vitrine responsive pour un artisan. Favoriser sa visibilité et exposer ses œuvres. Formulaire de contact. Réalisé avec HTML/SCSS, JavaScript et PHP.
                </p>
                <a href="https://www.ei-thomacon.com" target="_blank">VISITER</a>
            </div>
            <div className="project">
                <div className="project-image quai-antique" onClick={() => window.open("https://www.ecf-quai-antique.fr", "_blank")}></div>
                <h3>Le Quai Antique</h3>
                <p>
                Projet scolaire. Créer une application Web pour un chef. Favoriser sa visibilité, afficher ses œuvres et gérer le contenu du site web depuis un panneau d'administration. Réalisé avec HTML/SCSS, JavaScript vanilla et PHP POO MVC.
                </p>
                <a href="https://www.ecf-quai-antique.fr" target="_blank">VISITER</a>
            </div>
            <div className="project">
                <div className="project-image garage-parrot" onClick={() => window.open("https://test-front-production.up.railway.app/", "_blank")}></div>
                <h3>Garage Parrot</h3>
                <p>
                    Projet scolaire. Création d'une application web pour un garage. Pannel de gestion de contenu pour administrateur et employé. Réalisé en React et Node.JS.
                </p>
                <a href="https://test-front-production.up.railway.app/" target="_blank">VISITER</a>
            </div>
        </section>
    )
}

export default Projects