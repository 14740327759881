import { CiMenuFries } from "react-icons/ci"
import { IoIosClose } from "react-icons/io"
import { useState } from "react"

function Nav() {

    const [isOpen, setIsOpen] = useState(false)
    

    return(
        <>
            <nav>
                <a href="/">Téo Salmeron</a>
                <button onClick={() => setIsOpen(!isOpen)}>
                    <CiMenuFries />
                </button>
            </nav>
            <ul id="navList" className={`navbar ${isOpen ? 'open' : ''}`}>
                <button onClick={() => setIsOpen(!isOpen)}>
                    <IoIosClose />
                </button>
                <li>
                    <a href="#hero" onClick={() => setIsOpen(!isOpen)}>Accueil</a>
                </li>
                <li>
                    <a href="#about" onClick={() => setIsOpen(!isOpen)}>À propos</a>
                </li>
                <li>
                    <a href="#skills" onClick={() => setIsOpen(!isOpen)}>Compétences</a>
                </li>
                <li>
                    <a href="#projects" onClick={() => setIsOpen(!isOpen)}>Projets</a>
                </li>
                <li>
                    <a href="#contactMe" onClick={() => setIsOpen(!isOpen)}>Contact</a>
                </li>
            </ul>
        </>
    )
}

export default Nav