import ScrollReveal from "scrollreveal"
import { useEffect } from "react"

function Header() {

    useEffect(() => {
        ScrollReveal().reveal(".sequenced", {
            interval: 200,
            duration: 1200,
        })
    })

    return(
        <header id="about" className="block">
            <div className="about-text">
                <h2 className="sequenced">Qui suis-je ?</h2>
                <p className="sequenced">
                    Bienvenue sur mon portfolio. Je m'appelle Téo Salmeron, et je suis passionné par le développement web, cherchant toujours à fusionner simplicité et innovation dans mon travail. À travers l'usage de technologies telles que HTML5/CSS3, PHP, React, et Express Node.js, je me consacre à créer des expériences web qui sont à la fois intuitives et engageantes.
                </p>
                <p className="sequenced">
                Mon objectif est d'apporter des solutions sur mesure qui répondent aux besoins uniques de chaque projet, en mettant l'accent sur une communication claire et une collaboration efficace. Si vous êtes à la recherche d'un développeur web dédié à transformer vos idées en réalité digitale, je serais ravi de discuter de comment nous pouvons travailler ensemble. 
                </p>
            </div>
        </header>
    )
}

export default Header 