import { useEffect } from "react"
import { Parallax } from "react-scroll-parallax"
import ScrollReveal from "scrollreveal"

function Hero() {

    useEffect(() => {
        ScrollReveal().reveal(".h1", {
            duration: 2100,
        })

        ScrollReveal().reveal(".h2", {
            duration: 2100,
            delay: 200
        })
    }, [])

    return(
        <section id="hero">
            <h1 className="h1">
                Bonjour! Je suis Téo
            </h1>
            <h2 className="h2">
                Développeur web Junior
            </h2>
        </section>
    )
}

export default Hero