import ScrollReveal from "scrollreveal"
import { useEffect } from "react"

function Skills() {

    useEffect(() => {
        ScrollReveal().reveal(".sequenced", {
            duration: 2100,
            interval: 200,
        })
    })

    return(
        <section id="skills" className="block">
            <h2 className="sequenced">Compétences</h2>
            <div className="skill-box box1">
                <h3 className="sequenced">Front-End</h3>
                <p className="sequenced">
                Je suis capable de concevoir des sites Web modernes, réactifs et accessibles, m'assurant qu'ils soient à la fois attrayants visuellement et conviviaux sur tous les appareils. Mon expérience s'étend à l'amélioration du dynamisme et de l'interactivité de ces sites pour offrir une expérience utilisateur fluide et engageante.
                </p>
                <div className="skill-level sequenced">
                    <h4>HTML5</h4>
                    <progress max="100" value="80"></progress>
                </div>
                <div className="skill-level sequenced">
                    <h4>CSS3</h4>
                    <progress max="100" value="80"></progress>
                </div>
                <div className="skill-level sequenced">
                    <h4>JavaScript</h4>
                    <progress max="100" value="70"></progress>
                </div>
                <div className="skill-level sequenced">
                    <h4>React</h4>
                    <progress max="100" value="70"></progress>
                </div>
            </div>
            <div className="skill-box box2">
                <h3 className="sequenced">Back-End</h3>
                <p className="sequenced">
                Je suis à l'aise avec le développement back-end, notamment en PHP, SQL et Node.js. J'ai de l'expérience dans la création et la gestion de bases de données relationnelles, ainsi que dans l'élaboration de spécifications techniques et fonctionnelles pour garantir la qualité des projets.                </p>
                <div className="skill-level sequenced">
                    <h4>PHP</h4>
                    <progress max="100" value="80"></progress>
                </div>
                <div className="skill-level sequenced">
                    <h4>Node.js</h4>
                    <progress max="100" value="65"></progress>
                </div>
                <div className="skill-level sequenced">
                    <h4>SQL</h4>
                    <progress max="100" value="60"></progress>
                </div>
                <div className="skill-level sequenced">
                    <h4>Symfony</h4>
                    <progress max="100" value="50"></progress>
                </div>
            </div>
        </section>
    )  
}

export default Skills