import emailjs from 'emailjs-com';
import { useState } from 'react';

function ContactMe() {

    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [mailResponse, setMailResponse] = useState("")
    const [color, setColor] = useState("")

    const sendEmail = (formData) => {
        emailjs.send("service_ca7840l", "template_eef3wtn", formData, "pxvAVBp0njf0n1F9m")
        .then((response) => {
            setMailResponse("Votre message a été envoyé")
            setColor("green")
        }, (error) => {
            setMailResponse("Erreur, votre message n'a pas pu être envoyé")
            setColor("red")
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()

        sendEmail({ email, subject, message })
    }

    return(
        <section id="contactMe" className="block">
            <h2>Me contacter</h2>
            <p>Ne laissez pas votre projet web juste être une idée. Contactons-nous ! Parlons-en autour d'un café virtuel (ou réel) pour voir comment je peux vous aider.</p>
            <form onSubmit={handleSubmit}>
                <p style={{color: color}}>{mailResponse}</p>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='E-mail' required/>
                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Objet" required/>
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" required></textarea>
                <button type="submit">Envoyer</button>
            </form>
        </section>
    )
}

export default ContactMe